import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Popover } from '@mui/material';
import { StyledTypographyItem } from './styles';
import StatusChangeModal from 'components/Order/OrderStatusChange/StatusChangeModal';
import { SETTINGS_TABLE_OPTIONS } from 'pages/TableOrdering/hooks/useTableOrderingSettings';
import { DISPATCH_DELIVERY_CARRIER } from '../OrderDeliverDetails/hooks/useDeliveryDispatchDetails';
import DeliveryOrderCancelModal from 'components/Order/DeliveryOrderCancelModal/DeliveryOrderCancelModal';

const OrderStatusChange = ({ open, id, anchorEl, handleClose, data }) => {
  const { t } = useTranslation();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [cancelDeliveryModal, setCancelDeliveryModal] = useState(false);
  const openChangeOrderStatusModal = () => {
    setOpenStatusModal(true);
    handleClose();
  };
  const closeStatusModal = () => setOpenStatusModal(false);
  const closeDeliveryCancelModal = () => setCancelDeliveryModal(false);

  const openDeliveryCancelModal = () => {
    setCancelDeliveryModal(true);
    handleClose();
  };

  const requestDelivery = () => {
    const orderNumber = data?.number;
    const businessId = data?.business[0]?._id;
    window.open(`${process.env.REACT_APP_MANUAL_DELIVERY_URL}/?on=${orderNumber}&bid=${businessId}`, '_blank');
  };


  const showDispatchCancel = useMemo(() => {
    return data?.delivery?.deliveryCarrier?.toUpperCase() === DISPATCH_DELIVERY_CARRIER.DISPATCH;
  }, [data]);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper sx={{ width: '352px' }}>
          <StyledTypographyItem onClick={openChangeOrderStatusModal}>
            {t('orders_history.change_status')}
          </StyledTypographyItem>
          {data?.type?.toLowerCase() === SETTINGS_TABLE_OPTIONS.DELIVERY && showDispatchCancel ? (
            <StyledTypographyItem onClick={openDeliveryCancelModal}>{t('Cancel Delivery')}</StyledTypographyItem>
          ) : (
            <></>
          )}
          {data?.type?.toLowerCase() === SETTINGS_TABLE_OPTIONS.DELIVERY && showDispatchCancel ? (
            <StyledTypographyItem onClick={requestDelivery}>{t('Request Delivery')}</StyledTypographyItem>
          ) : (
            <></>
          )}
        </Paper>
      </Popover>
      <StatusChangeModal openStatusModal={openStatusModal} closeStatusModal={closeStatusModal} data={data} />
      {data?.type?.toLowerCase() === SETTINGS_TABLE_OPTIONS.DELIVERY && showDispatchCancel ? (
        <DeliveryOrderCancelModal
          cancelDeliveryModal={cancelDeliveryModal}
          closeDeliveryCancelModal={closeDeliveryCancelModal}
          data={data}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default OrderStatusChange;
