import { memo, useState } from 'react';
import { Collapse, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DISPATCH_BTN_ENUM } from 'components/Order/OrderDeliverDetails/hooks/useDeliveryDispatchDetails';
import { StyledShowMoreLessButton, StyledShowMoreLessLink, StyledShowMoreLessText } from '../OrderStatusChange/styles';


const ProviderDeliveryDetails = ({ deliveryData, providers }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = () => setShowDetails(prevState => !prevState);

  return (
    <>
      <Collapse in={showDetails}>
        <Box>
          <StyledShowMoreLessText>
            {t('Delivered by')}{' '}
            {deliveryData.delivery?.deliveryCarrier === 'self' ? (
              t('website_settings.in_house_delivery').toUpperCase()
            ) : (
              <>
                {t('order_view.delivery_by')}{' '}
                {deliveryData?.provider?.toUpperCase() === providers.food2.srv
                  ? deliveryData?.provider?.toUpperCase()
                  : deliveryData?.delivery?.deliveryCarrier?.toUpperCase()}
              </>
            )}
          </StyledShowMoreLessText>
          <StyledShowMoreLessText>
            {t('Phone Number:')} {deliveryData?.phoneNumber || deliveryData?.phone || '-'}
          </StyledShowMoreLessText>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <StyledShowMoreLessText>
              {t('Tracking URL:')}
            </StyledShowMoreLessText>
            {deliveryData?.delivery?.trackingUrl ? (
              <StyledShowMoreLessLink
                href={deliveryData?.delivery?.trackingUrl}
                target={'_blank'}>Link</StyledShowMoreLessLink>
            ) : (
              <>-</>
            )}
          </Box>
          <StyledShowMoreLessText>
            {t('order_view.reference_code')}: {deliveryData?.referenceCode || '-'}
          </StyledShowMoreLessText>
        </Box>
      </Collapse>
      <StyledShowMoreLessButton
        sx={{ mt: showDetails ? 2 : 0 }}
        variant="outlined"
        onClick={handleClick}
      >
        {showDetails
          ? DISPATCH_BTN_ENUM.SHOW_LESS
          : DISPATCH_BTN_ENUM.SHOW_MORE}
      </StyledShowMoreLessButton>
    </>
  )
}

export default memo(ProviderDeliveryDetails);