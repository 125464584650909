import { useEffect, useMemo, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Business from './Business';
// import AccountInfo from './Settings/AccountInfo';
import UsersRoles from './UsersRoles';
import BillingInfo from './BillingInfo';
import Events from './Events/Events';
import Advanced from './Advanced/index';
import BrandLocations from './BrandLocations/index';
import Directory from './Directory/Directory';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import ProtectedRoute from 'components/ProtectedRoute';
import * as all from 'constants/permissions';

import usePermissionPlan from 'hooks/permissionPlan';
import usePermission from 'hooks/permission';
import Admin from './Admin';
import Printers from './Printers/Printers';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { isPermissionWithCompanyType } from '../../hooks/useCompanyType';
import { isBrand } from 'hooks/useBrandType';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import Language from '@mui/icons-material/Language';
import LanguageSettings from './LanguageSettings/LanguageSettings';
import Account from 'redesign/AccountTab/Account';
import EmployeesAndJobs from './EmployeesAndJobs';

const excludeRoutes = [
  '/settings/printers',
  '/settings/events',
  '/settings/billing',
];
const excludeRoutesForSingel = ['/settings/billing'];
const excludeRoutesForBrand = [
  '/settings/business',
  '/settings/billing',
  '/settings/printers',
  '/settings/events',
  '/settings/brands',
];

const routes = [
  {
    id: 'SETTINGS_BUSINESS',
    plan: '',
    path: '/business',
    name: 'settings.business',
  },
  {
    id: 'SETTINGS_USERS',
    plan: '',
    path: '/usersAndRoles',
    name: 'settings_routes.users_and_roles',
  },
  {
    id: 'SETTINGS_USERS',
    plan: '',
    path: '/employeesAndJobs',
    name: 'Employees and Jobs',
  },
  // { id: 'SETTINGS_WEBSITE', plan: 'WEB_BUNDLE', path: '/website', name: 'Website' },
  {
    id: 'SETTINGS_BILLING',
    plan: '',
    path: '/billing',
    name: 'settings_routes.billing',
  },
  {
    id: 'SETTINGS_PRINTERS',
    plan: '',
    path: '/printers',
    name: 'settings_routes.printer',
  },
  {
    id: 'SETTINGS_EVENTS',
    plan: '',
    path: '/events',
    name: 'settings_routes.events_notifications',
  },
  {
    id: 'SETTINGS_BRAND_LOCATIONS',
    plan: '',
    path: '/brands',
    name: 'settings_routes.brands_locations',
  },
  {
    id: 'SETTINGS_ACCOUNT',
    plan: '',
    path: '/account',
    name: 'settings.account',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/advanced',
    name: 'settings_routes.advanced',
  },
  // { id: 'SETTINGS_LANGUAGE', plan: '', path: '/language', name: 'settings_routes.language' },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/admin',
    name: 'settings_routes.admin',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/marketing',
    name: 'settings_routes.marketing',
  },
];

const routesForTypeOfIsNotSingle = [
  {
    id: 'SETTINGS_BUSINESS',
    plan: '',
    path: '/business',
    name: 'settings.business',
  },
  {
    id: 'SETTINGS_USERS',
    plan: '',
    path: '/usersAndRoles',
    name: 'settings_routes.users_and_roles',
  },
  {
    id: 'SETTINGS_USERS',
    plan: '',
    path: '/employeesAndJobs',
    name: 'Employees and Jobs',
  },
  {
    id: 'SETTINGS_BILLING',
    plan: '',
    path: '/billing',
    name: 'settings_routes.billing',
  },
  {
    id: 'SETTINGS_BRAND_LOCATIONS',
    plan: '',
    path: '/brands',
    name: 'settings_routes.brands_locations',
  },
  {
    id: 'SETTINGS_ACCOUNT',
    plan: '',
    path: '/account',
    name: 'settings.account',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/advanced',
    name: 'settings_routes.advanced',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/admin',
    name: 'settings_routes.admin',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/marketing',
    name: 'settings_routes.marketing',
  },
];
const routesForTypeOfBrand = [
  {
    id: 'SETTINGS_USERS',
    plan: '',
    path: '/usersAndRoles',
    name: 'settings_routes.users_and_roles',
  },
  {
    id: 'SETTINGS_USERS',
    plan: '',
    path: '/employeesAndJobs',
    name: 'Employees and Jobs',
  },
  {
    id: 'SETTINGS_ACCOUNT',
    plan: '',
    path: '/account',
    name: 'settings.account',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/advanced',
    name: 'settings_routes.advanced',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/admin',
    name: 'settings_routes.admin',
  },
  {
    id: 'SETTINGS_ADMIN',
    plan: '',
    path: '/marketing',
    name: 'settings_routes.marketing',
  },
];
// const routesForTypeOfIsNotSingle = [
//   { id: 'SETTINGS_ACCOUNT', plan: '', path: '/account', name: 'Account' },
//   { id: 'SETTINGS_BUSINESS', plan: '', path: '/business', name: ' Business' },
//   { id: 'SETTINGS_USERS', plan: '', path: '/usersAndRoles', name: 'Users and Roles' },
//   { id: 'SETTINGS_BILLING', plan: '', path: '/billing', name: 'Billing Info' },
//   { id: 'SETTINGS_BRAND_LOCATIONS', plan: '', path: '/brands', name: 'Brand / Locations' },
//   { id: 'SETTINGS_ADMIN', plan: '', path: '/admin', name: 'Admin' },
//   { id: 'SETTINGS_ADMIN', plan: '', path: '/directory', name: 'Directory' },
// ];
// const routesForTypeOfBrand = [
//   { id: 'SETTINGS_ACCOUNT', plan: '', path: '/account', name: 'Account' },
//   { id: 'SETTINGS_USERS', plan: '', path: '/usersAndRoles', name: 'Users and Roles' },
//   { id: 'SETTINGS_ADMIN', plan: '', path: '/admin', name: 'Admin' },
//   { id: 'SETTINGS_ADMIN', plan: '', path: '/directory', name: 'Directory' },
// ];

const Settings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isTypeBrand } = isBrand();
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { currentBusiness } = useSelector(({ business }) => business);
  const currentTab = localStorage.getItem('currentTab');

  const filteredRoutes = useMemo(() => {
    const currentTab = localStorage.getItem('currentTab');
    if (isTypeSingle) {
      let tabs = routes.filter(({ plan, id }) => {
        return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
      });
      if (
        !checkPermission(all['SUPER']) &&
        checkPermissionPlan(all['EXTERNAL_BUNDLE'])
      ) {
        tabs = tabs.filter((tab) => {
          return ['/business', '/usersAndRoles', '/account'].includes(tab.path);
        });
      }
      tabs.forEach((tab, index) => {
        if (tab.path === currentTab) {
          return history.push(
            `/settings${currentTab}${history.location.search}`
          );
        } else if (history.location.pathname.includes(tab.path)) {
          localStorage.setItem('currentTab', tab.path);
          return history.push(`/settings${tab.path}${history.location.search}`);
        } else {
          const currentTabAfterSet = localStorage.getItem('currentTab');
          if (
            excludeRoutesForSingel.includes(currentTabAfterSet) &&
            index === tabs?.length - 1
          ) {
            return history.push('/settings/business');
          }
        }
      });
      const currentTabAfterSet = localStorage.getItem('currentTab');
      if (!currentTabAfterSet) history.push('/settings/business');
      if (tabs.length === 2) {
        if (currentTabAfterSet) {
          history.push(`/settings${currentTabAfterSet}`);
          return tabs;
        } else {
          history.push('/settings/printers');
          return tabs;
        }
      }
      return tabs;
    } else if (isTypeBrand) {
      let singleTab = 1;
      const tabs = routesForTypeOfBrand.filter(({ plan, id }) => {
        return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
      });
      if (tabs.length === singleTab) {
        history.push('/settings/account');
      } else {
        if (excludeRoutesForBrand.includes(history.location.pathname)) {
          history.push('/settings/usersAndRoles');
        }
      }
      return [
        ...routesForTypeOfBrand.filter(({ plan, id }) => {
          return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
        }),
      ];
    } else {
      let singleTab = 1;
      const tabs = routesForTypeOfIsNotSingle.filter(({ plan, id }) => {
        return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
      });
      if (tabs.length === singleTab) {
        history.push(`/settings${tabs[0].path}`);
      } else if (excludeRoutes.includes(history.location.pathname)) {
        history.push('/settings/business');
      }
      return [
        ...routesForTypeOfIsNotSingle.filter(({ plan, id }) => {
          return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
        }),
      ];
    }
  }, [
    checkPermission,
    checkPermissionPlan,
    isTypeSingle,
    isTypeBrand,
    currentBusiness,
  ]);

  const [value, setValue] = useState(history.location.pathname);


  const handleChange = (event, newValue) => {
    // setValue(newValue);
    history.push(newValue);
    if (!history.location.pathname.includes(currentTab)) {
      localStorage.setItem('currentTab', newValue.slice(9, newValue.length));
    }
  };

  useEffect(() => {
    localStorage.setItem('currentTab', history.location.pathname);
    setValue(history.location.pathname);
  }, [history.location]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('currentTab');
    };
  }, []);

  return (
    <Paper sx={{ pb: 2 }}>
      <Box sx={{ width: '100%' }}>
        <Tabs
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label="scrollable force tabs example"
        >
          {filteredRoutes.map(({ id, path, name }, index) => (
            <Tab
              key={`${id || path}_${index}`}
              value={`/settings${path}`}
              label={t(name)}
              sx={{ minWidth: 0 }}
            />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ padding: '24px' }}>
        <ErrorBoundaryWrapper>
          <Switch>
            <ProtectedRoute
              exact
              path="/settings/account"
              component={Account}
              permission="SETTINGS_ACCOUNT"
            />
            {/* <ProtectedRoute exact component={Website} path="/settings/website" permission="SETTINGS_WEBSITE" /> */}
            <ProtectedRoute
              exact
              component={Business}
              path="/settings/business"
              permission="SETTINGS_BUSINESS"
            />
            <ProtectedRoute
              exact
              component={BillingInfo}
              path="/settings/billing"
              permission="SETTINGS_BILLING"
            />
            <ProtectedRoute
              exact
              component={UsersRoles}
              path="/settings/usersAndRoles"
              permission="SETTINGS_USERS"
            />
            <ProtectedRoute
              exact
              component={EmployeesAndJobs}
              path="/settings/employeesAndJobs"
              permission="SETTINGS_USERS"
            />
            <ProtectedRoute
              exact
              component={Directory}
              path="/settings/marketing"
              permission="SETTINGS_ADMIN"
            />
            <ProtectedRoute
              exact
              component={Admin}
              path="/settings/admin"
              permission="SETTINGS_ADMIN"
            />
            <ProtectedRoute
              exact
              component={Printers}
              path="/settings/printers"
              permission="SETTINGS_PRINTERS"
            />
            <ProtectedRoute
              exact
              component={Events}
              path="/settings/events"
              permission="SETTINGS_EVENTS"
            />
            <ProtectedRoute
              exact
              component={LanguageSettings}
              path="/settings/language"
              permission="SETTINGS_BUSINESS"
            />
            <ProtectedRoute
              exact
              component={Advanced}
              path="/settings/advanced"
              permission="SETTINGS_BUSINESS"
            />

            <ProtectedRoute
              exact
              component={BrandLocations}
              path="/settings/brands"
              permission="SETTINGS_BRAND_LOCATIONS"
            />
            <Redirect to={`/settings${filteredRoutes?.[0]?.path}`} />
          </Switch>
        </ErrorBoundaryWrapper>
      </Box>
    </Paper>
  );
};

export default Settings;
